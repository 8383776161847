import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Alert } from "antd";
import axiosInstance from "../../../util/auth/axiosInstance";
import { useNavigate, useParams, Link, useSearchParams  } from "react-router-dom";
import { BackwardOutlined } from "@ant-design/icons";

const FpoCreate = () => 
{
	const [searchParams, setSearchParams] = useSearchParams();
	const [errorMessage, setErrorMessage] = useState('');
	const [form] = Form.useForm();
	
	const [isSubmit, setIsSubmit] = useState(false);
	const navigate = useNavigate();
	const { id } = useParams();

	const createApi = async (formData) => 
	{
		setErrorMessage('');
		try 
		{
			await axiosInstance.post(`/api/fpo`,formData);
			navigate("/admin/fpo");
		} 
		catch (error) 
		{
			setIsSubmit(false);
			if (error.response) 
			{
				console.log(error.response.data);
				console.log(error.response.status);
				setErrorMessage(error.response.data.message);
			} 
			else if (error.request) 
			{
				console.log(error.request);
				setErrorMessage(error.request);
			} 
			else 
			{
				console.log('Error', error.message);
				setErrorMessage(error.message);
			}	
		}
	};

	const updateApi = async (id,values) => 
	{
		setErrorMessage('');
		try 
		{
			await axiosInstance.put(`/api/fpo/`+id,values);
			navigate("/admin/fpo"+'?page='+searchParams.get('page'));
		} 
		catch (error) 
		{
			setIsSubmit(false);
			if (error.response) 
			{
				console.log(error.response.data);
				console.log(error.response.status);
				setErrorMessage(error.response.data.message);
			} 
			else if (error.request) 
			{
				console.log(error.request);
				setErrorMessage(error.request);
			} 
			else 
			{
				console.log('Error', error.message);
				setErrorMessage(error.message);
			}
		}
	};

	useEffect(() => 
	{
		const getFpo = async (id) =>
		{
			try 
			{
				const response = await axiosInstance.get(`${process.env.REACT_APP_API_HOST}/api/fpo/`+id);	
				form.setFieldsValue({
					fpo_code: response.data.fpo_code,
					url: response.data.url,
				});
			} 
			catch (error) 
			{
				if (error.response) 
				{
					console.log(error.response.data);
					console.log(error.response.status);
					setErrorMessage(error.response.data.message);
				} 
				else if (error.request) 
				{
					console.log(error.request);
					setErrorMessage(error.request);
				} 
				else 
				{
					console.log('Error', error.message);
					setErrorMessage(error.message);
				}	
			}
		}
		if(id)
		{
			getFpo(id);
		}
	}, [id]);

	const onFinish = values => 
	{
		setIsSubmit(true);
		if(id)
		{
			updateApi(id, values);
		}
		else
		{
			createApi(values);
		}
	};

  return (
		<Row justify="end">
			<Col lg={2}>
				<Link to={id ? '../fpo'+'?page='+searchParams.get('page') : './../'}>
					<Button type="primary" shape="round" icon={<BackwardOutlined />}>
						Back
					</Button>
				</Link>
			</Col>
			<Col span={24}>
				<Form onFinish={onFinish} form={form}>
                    <Row gutter={24}>
                        <Col md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <Form.Item
                                label="FPO Code"
                                name="fpo_code"
                                rules={[{ required: true, message: "FPO Code is required" }]}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <Form.Item
                                label="URL"
                                name="url"
                                rules={[{ required: true, message: "URL is required" },{ type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={{ span: 24 }}>
                            {
                            errorMessage !== '' 
                            ?
                                (<Alert
                                    message={errorMessage}
                                    type="error"
                                    style={{marginBottom : '15px'}}
                                />) 
                            : 
                            ''
                            }
                        </Col>
                        <Col md={{ span: 24 }} style={{display: 'flex', justifyContent: 'flex-end', paddingRight:40}}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" disabled={isSubmit}>
                                    Save
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>					
				</Form>
			</Col>
		</Row>
  );
};

export default FpoCreate;
