import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Space, Popconfirm, Image, Modal} from 'antd';
import qs from 'qs';
import { PlusOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "../../../util/auth/axiosInstance";

const getRandomuserParams = (params) => ({
	results: params.pagination?.pageSize,
	page: params.pagination?.current,
	...params,
});
const FpoList = () => 
{
	const [data, setData] = useState();
	const [loading, setLoading] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	const [tableParams, setTableParams] = useState({
		pagination: {
			current: searchParams.get('page') !== null ? parseInt(searchParams.get('page')) : 1,
			pageSize: 20,
		},
	});
	

	const fetchData = () => 
	{
		setLoading(true);
		axiosInstance(`/api/fpo?${qs.stringify(getRandomuserParams(tableParams))}`)
		.then((res) => res.data)
		.then(({ results,total }) => 
		{
			setData(results);
			setLoading(false);
			setTableParams({
				...tableParams,
				pagination: 
				{
				...tableParams.pagination,
				total: total,
				},
			});
		})
		.catch(err => {
			console.log(err);
		});
	};

  const columns = [
	{
		title: 'FPO Code',
		dataIndex: 'fpo_code',
	},
	{
		title: 'URL',
		dataIndex: 'url',
	},
	{
		title: 'Action',
		key: 'action',
		render: (_, record) => (
		<Space size="middle">
			<Link to={'edit/'+record.id+'?page='+tableParams.pagination.current}>
			<Button shape="circle" icon={<EditOutlined />} title='Edit FPO Code' />
			</Link>
			<Popconfirm title="Are sure delete this Ads?" onConfirm={() => handleDelete(record.id)}>
			<Button shape="circle" icon={<DeleteOutlined />}  title='Delete FPO'  />
			</Popconfirm>
			
		</Space>
		),
	},
  ];
  
	const handleDelete = async (id) =>
	{
		try 
		{
			await axiosInstance.delete(`${process.env.REACT_APP_API_HOST}/api/fpo/`+id);
			fetchData();
		} 
		catch (error) 
		{
			console.log(error);
		}
	}

	useEffect(() => 
	{
		fetchData();
	}, [JSON.stringify(tableParams)]);

	const handleTableChange = (pagination, filters, sorter) => 
	{
		searchParams.set('page', pagination.current)
		setSearchParams(searchParams)
		setTableParams({
			pagination,
			filters,
			...sorter,
		});

		// `dataSource` is useless since `pageSize` changed
		if (pagination.pageSize !== tableParams.pagination?.pageSize) {
			setData([]);
		}
	};
  
  return (
    <Row justify="end">
        <Col lg={3} style={{ marginBottom:'15px',}}>
            <Link to='create'>
                <Button type="primary" shape="round" icon={<PlusOutlined />}>
                    New Ads
                </Button>
            </Link>
        </Col>
        <Col span={24}>
			<Table
                bordered
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={loading}
                onChange={handleTableChange}
			/>
        </Col>
      </Row>
  );
};
export default FpoList;