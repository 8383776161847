import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST, // Your API base URL
});

axiosInstance.interceptors.request.use((config) => 
{
    const token = localStorage.getItem('token'); // Assuming you're storing your access token in localStorage
    if (token) 
    {
        config.headers['x-access-token'] = token;
    }
        return config;
},(error) => 
{
    console.log(error);
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => 
{
    return response
}, function (error) 
{
    if (error.response.status === 401) 
    {
        window.location = '/login';
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export default axiosInstance;
